import axios from 'axios';

import {
  showLoadingSpinner,
  hideLoadingSpinner,
} from '../system/spinnerControl';

const url = process.env.REACT_APP_AUTOMATION_API_URL;

axios.defaults.withCredentials = true;

const defaultHeaders = {
  'Content-Type': 'application/vnd.api+json',
};

axios.interceptors.request.use(
  function (config) {
    showLoadingSpinner();
    return config;
  },
  function (error) {
    hideLoadingSpinner();
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    hideLoadingSpinner();
    return response;
  },
  function (error) {
    hideLoadingSpinner();
    return Promise.reject(error);
  }
);

async function get(resource) {
  let config = injectTokenHeader(defaultHeaders);
  let response = await axios.get(`${url}${resource}`, {
    headers: config,
  });
  return response;
}

async function remove(resource) {
  let config = injectTokenHeader(defaultHeaders);
  let response = await axios.delete(`${url}${resource}`, {
    headers: config,
  });
  return response;
}

async function post(resource, body, customHeader) {
  let config = injectTokenHeader(
    customHeader !== null && customHeader !== void 0
      ? customHeader
      : defaultHeaders
  );
  let response = await axios.post(`${url}${resource}`, body, {
    headers: config,
  });
  return response;
}

async function put(resource, body, customHeader) {
  let config = injectTokenHeader(
    customHeader !== null && customHeader !== void 0
      ? customHeader
      : defaultHeaders
  );
  let response = await axios.put(`${url}${resource}`, body, {
    headers: config,
  });
  return response;
}
async function patch(resource, body, customHeader) {
  let config = injectTokenHeader(
    customHeader !== null && customHeader !== void 0
      ? customHeader
      : defaultHeaders
  );
  let response = await axios.patch(`${url}${resource}`, body, {
    headers: config,
  });
  return response;
}

function injectTokenHeader(headers) {
  // let token = getToken();
  // if (token === null || token.trim() === "") {
  //   return headers;
  // }
  // return Object.assign(Object.assign({}, headers), {
  //   Authorization: `Bearer ${token}`,
  //   "x-api-key": "123",
  // });
  return headers;
}
export { get, post, put, patch, remove };
